<template>
  <div>
    <Header />
    <div class="page-title">
      <div class="container">
        <div class="title text-black">Contato</div>
      </div>
    </div>
    <section class="contato">
      <div class="container">
        <div class="block desktop6 tablet6 mobile12 pr-2-desktop pr-1-tablet">
          <div class="address-item">
            <div class="before-title address">ESCRITÓRIO ADMINISTRATIVO</div>
            <div class="city">Hortolândia - SP</div>
            <p class="text-desc mt-1">
              Condomínio Tech Town - Escritório Unidade 30
            </p>
            <p class="text-desc">
              Rod. Jornalista Francisco Aguirre Proença, Km 9,5 - Chácaras Assay
            </p>
            <p class="text-desc">Hortolândia/SP - CEP: 13186-904</p>
            <div @click="showMap1 = !showMap1" class="btn border icon address">
              <span>VER MAPA</span>
              <font-awesome-icon :icon="['fas', 'sort-down']" />
            </div>
            <transition
              enter-active-class="animated fadeInLeft"
              leave-active-class="animated fadeOutLeft"
            >
              <iframe
                v-show="showMap1"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3675.382612177087!2d-47.200415884406084!3d-22.899254343360663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c8b859c66448e5%3A0xa8fcaf152f44a5ef!2sCondom%C3%ADnio%20Tech%20Town!5e0!3m2!1spt-BR!2sbr!4v1629987836907!5m2!1spt-BR!2sbr"
                frameborder="0"
                style="border: 0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </transition>
          </div>

          <div class="address-item mt-2">
            <div class="before-title address">GR LOGÍSTICA</div>
            <div class="city">Hortolândia - SP</div>
            <p class="text-desc mt-1">
              Rua Pérola, 350 - Jardim Santa Esmeralda
            </p>
            <p class="text-desc">Hortolândia/SP - CEP: 13186-546</p>
            <p class="text-desc">Armazéns 09 / 10 / 11 / 12</p>
            <div @click="showMap2 = !showMap2" class="btn border icon address">
              <span>VER MAPA</span>
              <font-awesome-icon :icon="['fas', 'sort-down']" />
            </div>
            <transition
              enter-active-class="animated fadeInLeft"
              leave-active-class="animated fadeOutLeft"
            >
              <iframe
                v-show="showMap2"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3675.218243542064!2d-47.20041858440612!3d-22.905319743578627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c8b999a0aa0009%3A0x79782ed540e4da45!2sR.%20P%C3%A9rola%2C%20350%20-%20Jardim%20Santa%20Esmeralda%2C%20Hortol%C3%A2ndia%20-%20SP%2C%2013186-546!5e0!3m2!1spt-BR!2sbr!4v1629987746317!5m2!1spt-BR!2sbr"
                frameborder="0"
                style="border: 0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </transition>
          </div>

          <div class="address-item mt-2">
            <div class="before-title address">FÁBRICA</div>
            <div class="city">Monte Mor - SP</div>
            <p class="text-desc mt-1">
              Rodovia SP 101, km 32 - Bairro Sobradinho
            </p>
            <p class="text-desc">Monte Mor/SP – CEP: 13199-899</p>
            <p class="text-desc">Fone: (19) 3889-2998</p>
            <div @click="showMap3 = !showMap3" class="btn border icon address">
              <span>VER MAPA</span>
              <font-awesome-icon :icon="['fas', 'sort-down']" />
            </div>
            <transition
              enter-active-class="animated fadeInLeft"
              leave-active-class="animated fadeOutLeft"
            >
              <iframe
                v-show="showMap3"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7346.237900046153!2d-47.37776119421266!3d-22.98265299999999!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7f6e665e8948a49d!2sMicroquimica%20Tradecorp!5e0!3m2!1spt-BR!2sbr!4v1644522357619!5m2!1spt-BR!2sbr"
                frameborder="0"
                style="border: 0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </transition>
          </div>
        </div>
        <div class="block desktop6 tablet6 mobile12 pl-1-desktop pl-1-tablet">
          <div class="form-holder">
            <div class="input-item mt-0-desktop">
              <span>Nome *</span>
              <input type="text" v-model="form.name" />
            </div>
            <div class="flex-between">
              <div class="input-item">
                <span>Telefone *</span>
                <input type="tel" v-model="form.phoneNumber" />
              </div>
              <div class="input-item">
                <span>E-mail *</span>
                <input type="email" v-model="form.email" />
              </div>
            </div>
            <div class="flex-between">
              <div class="input-item">
                <span>País *</span>
                <input type="text" v-model="form.country" />
              </div>
              <div class="input-item">
                <span>Estado *</span>
                <select v-model="form.state">
                  <option value="Acre">Acre</option>
                  <option value="Alagoas">Alagoas</option>
                  <option value="Amapá">Amapá</option>
                  <option value="Amazonas">Amazonas</option>
                  <option value="Bahia">Bahia</option>
                  <option value="Ceará">Ceará</option>
                  <option value="Distrito Federal">Distrito Federal</option>
                  <option value="Espírito Santo">Espírito Santo</option>
                  <option value="Goiás">Goiás</option>
                  <option value="Maranhão">Maranhão</option>
                  <option value="Mato Grosso">Mato Grosso</option>
                  <option value="Mato Grosso do Sul">Mato Grosso do Sul</option>
                  <option value="Minas Gerais">Minas Gerais</option>
                  <option value="Paraná">Paraná</option>
                  <option value="Paraíba">Paraíba</option>
                  <option value="Pará">Pará</option>
                  <option value="Pernambuco">Pernambuco</option>
                  <option value="Piauí">Piauí</option>
                  <option value="Rio de Janeiro">Rio de Janeiro</option>
                  <option value="Rio Grande do Norte">
                    Rio Grande do Norte
                  </option>
                  <option value="Rio Grande do Sul">Rio Grande do Sul</option>
                  <option value="Rondonia">Rondonia</option>
                  <option value="Roraima">Roraima</option>
                  <option value="Santa Catarina">Santa Catarina</option>
                  <option value="Sergipe">Sergipe</option>
                  <option value="São Paulo">São Paulo</option>
                  <option value="Tocantins">Tocantins</option>
                </select>
              </div>
            </div>
            <div class="flex-between">
              <div class="input-item">
                <span>Cidade *</span>
                <input type="text" v-model="form.city" />
              </div>
              <div class="input-item">
                <span>Data de nascimento *</span>
                <input type="date" v-model="form.birthdate" />
              </div>
            </div>
            <div class="input-item radio">
              <span>Ramo de atividade *</span>
              <div class="radio-item">
                <input
                  type="radio"
                  name="atividade"
                  id="radio1"
                  value="Produtor/Agricultor"
                  v-model="form.jobTitle"
                />
                <label for="radio1">Produtor/Agricultor</label>
              </div>
              <div class="radio-item">
                <input
                  type="radio"
                  name="atividade"
                  id="radio2"
                  value="Distribuidor/Cooperativa"
                  v-model="form.jobTitle"
                />
                <label for="radio2">Distribuidor/Cooperativa</label>
              </div>
              <div class="radio-item">
                <input
                  type="radio"
                  name="atividade"
                  id="radio3"
                  value="Assessoria Agrícola"
                  v-model="form.jobTitle"
                />
                <label for="radio3">Assessoria Agrícola</label>
              </div>
              <div class="radio-item">
                <input
                  type="radio"
                  name="atividade"
                  id="radio4"
                  value="Estudante/Acadêmico"
                  v-model="form.jobTitle"
                />
                <label for="radio4">Estudante/Acadêmico</label>
              </div>
              <div class="radio-item">
                <input
                  type="radio"
                  name="atividade"
                  id="radio5"
                  value="Outros"
                  v-model="form.jobTitle"
                />
                <label for="radio5">Outros</label>
              </div>
            </div>
            <div class="input-item">
              <span>Cultura de interesse *</span>
              <input type="text" v-model="form.culture" />
            </div>
            <div class="input-item">
              <span>Mensagem</span>
              <textarea v-model="form.message"></textarea>
            </div>

            <div class="politics-holder">
              <router-link to="/politica-de-privacidade"
                >Política de privacidade</router-link
              >
              <span class="text-desc"> e </span>
              <router-link to="/politica-de-cookies"
                >política de cookies</router-link
              >
            </div>

            <vue-recaptcha
              class="mt-1"
              ref="recaptcha"
              @verify="onVerify"
              @expired="onExpired"
              sitekey="6LcY6j8aAAAAAH3V89ex5-QQka24KD9ihjnZswW2"
              :loadRecaptchaScript="true"
            />

            <transition enter-active-class="animated fadeIn">
              <p
                v-show="warningMessage"
                class="warning red"
                style="margin-top: 20px; text-align: left"
              >
                {{ warningMessage }}
              </p>
            </transition>

            <div
              @click="sendForm"
              class="btn border icon send-form"
              v-show="validHuman"
            >
              <span v-show="!sendingForm">ENVIAR</span>
              <font-awesome-icon
                v-show="!sendingForm"
                :icon="['fas', 'chevron-right']"
              />
              <div v-show="sendingForm" class="loading2 yellow"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="contact">
      <div class="container">
        <div class="contact-item">
          <div class="contact-title text-center text-white">
            ÁREA CENTRAL DE VENDAS
          </div>
          <a
            class="text-desc text-center text-white"
            href="mailto:atendimento@tradecorp.rovensa.com"
            >atendimento@tradecorp.rovensa.com</a
          >
        </div>
        <div class="contact-item mt-2-mobile">
          <div class="contact-title text-center text-white">MARKETING</div>
          <a
            class="text-desc text-center text-white"
            href="mailto:marketing@tradecorp.rovensa.com"
            >marketing@tradecorp.rovensa.com</a
          >
        </div>
        <div class="contact-item mt-2-mobile">
          <div class="contact-title text-center text-white">
            RECURSOS HUMANOS
          </div>
          <a
            class="text-desc text-center text-white"
            href="mailto:recrutamento.brasil@tradecorp.rovensa.com"
            >recrutamento.brasil@tradecorp.rovensa.com</a
          >
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
import http from "@/http";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import validators from "../util/validators";
import VueRecaptcha from "vue-recaptcha";
export default {
  name: "Contato",
  components: {
    Header,
    Footer,
    "vue-recaptcha": VueRecaptcha,
  },
  data() {
    return {
      showMap1: false,
      showMap2: false,
      showMap3: false,
      sendingForm: false,
      warningMessage: "",
      form: {
        name: "",
        phoneNumber: "",
        email: "",
        country: "",
        state: "",
        city: "",
        birthdate: "",
        jobTitle: "",
        culture: "",
        message: "",
        subject: "Contato",
      },
      validHuman: false,
    };
  },
  created() {},
  methods: {
    onVerify: function (response) {
      console.log("Verify: " + response);
      this.validHuman = true;
    },
    onExpired: function () {
      console.log("Expired");
      this.validHuman = false;
    },
    sendForm() {
      if (!this.form.name) {
        this.warningMessage = "Por favor, informe o seu nome.";
        return;
      }
      if (this.form.phoneNumber.length < 7) {
        this.warningMessage = "Por favor, informe um telefone para contato.";
        return;
      }
      if (!validators.validateEmail(this.form.email)) {
        this.warningMessage = "Por favor, informe o seu e-mail.";
        return;
      }
      if (this.form.country.length < 2) {
        this.warningMessage = "Por favor, informe o seu país.";
        return;
      }
      if (!this.form.state) {
        this.warningMessage = "Por favor, informe o seu estado.";
        return;
      }
      if (this.form.city.length < 2) {
        this.warningMessage = "Por favor, informe o sua cidade.";
        return;
      }
      if (!this.form.birthdate) {
        this.warningMessage = "Por favor, informe o sua data de nascimento.";
        return;
      }
      if (this.form.jobTitle.length < 2) {
        this.warningMessage = "Por favor, informe o seu ramo de atividade.";
        return;
      }
      if (this.form.culture.length < 2) {
        this.warningMessage = "Por favor, informe o sua cultura de interesse.";
        return;
      }

      this.warningMessage = "";

      this.postForm();
    },
    clearForm() {
      this.form.name = "";
      this.form.phoneNumber = "";
      this.form.email = "";
      this.form.country = "";
      this.form.state = "";
      this.form.city = "";
      this.form.birthdate = "";
      this.form.jobTitle = "";
      this.form.culture = "";
      this.form.message = "";
    },
    postForm() {
      this.sendingForm = true;
      http
        .post("Contact", this.form)
        .then(() => {
          this.warningMessage = "Contato enviado com sucesso!";
          this.clearForm();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.sendingForm = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
section.contato .container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.address-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid #f1f1f1;
  iframe {
    position: relative;
    width: 100%;
    height: 260px;
    margin-top: 20px;
  }
}
.city {
  font-family: fontBold;
  font-size: 1.5em;
}

.before-title.address {
  margin-bottom: 0;
}

.btn.border.icon.address {
  padding: 16px 24px;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  margin-top: 15px;
  &:hover {
    border-color: var(--secondary);
    background-color: var(--secondary);
  }
  span {
    font-size: 1em;
  }
  svg {
    position: relative;
    top: -4px;
  }
}

.input-item.radio {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  span {
    position: relative;
    display: block;
    width: 100%;
  }
}

.radio-item {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  input[type="radio"] {
    width: 13px;
    height: 13px;
  }
  label {
    margin-left: 8px;
  }
}

section.contact {
  background-color: var(--bg-green);
  .container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-content: center;
    align-items: center;
    gap: 25px;
    .contact-item {
      position: relative;
      width: 100%;
      max-width: 300px;
      justify-self: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .contact-title {
        font-family: fontBold;
        font-size: 1.4em;
        margin-bottom: 15px;
      }
    }
  }
}

.politics-holder {
  margin-top: 10px;
  a {
    font-family: fontRegular;
    font-size: 1.1em;
    line-height: 1.5em;
    color: var(--green1);
  }
}

.btn.border.icon.send-form {
  display: inline-block;
  margin-top: 20px;
}

@media screen and(max-width: 720px) {
}

@media only screen and (min-width: 721px) and (max-width: 1139px) {
}
</style>
